const videoAttributes = {
	autoPlay: true,
	loop: true,
	playsInline: true,
	muted: true,
	disablePictureInPicture: true,
	pip: "false",
	width: "100%",
	height: "100%",
};

export default videoAttributes;
